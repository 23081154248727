.icon-shape {
  display: inline-flex;
  padding: 10px;
  text-align: center;
  font-size: 38px;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
}

.card.hover{
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;

}
.card.hover:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
.card.hover:hover .icon-shape{
  color: lightblue !important;
  transition: all 0.5s;

}
.card {
  transition: all 0.5s;
  border-radius: 10px;
}
.date-picker {
  position: relative;
  float: right;
  margin-top: -100px;
}
.rdrDateRangePickerWrapper.date-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  display: none;
}
.date-picker:hover .rdrDateRangePickerWrapper.date-dropdown{
  display: inline-flex;
}
.card-header:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-footer:last-child {
  border-radius: 10px;
}
.validation-error{
  color:red;
}